import { createGlobalStyle } from 'styled-components'
import * as fontFiles from './fonts/index'

// Colors
const blue = '#2a29a6'
const black = '#292929'
const lightBlue = '#dddfef'
const red = '#e93e57'
const white = '#ffffff'

export default createGlobalStyle`
  :root {
    --blue: ${blue};
    --blue-opaque: ${blue}80;
    --black: ${black};
    --lightBlue: ${lightBlue};
    --red: ${red};
    --white: ${white};
  }

  @font-face {
    font-family: BookmaniaBold;
    src: url(${fontFiles.Bookmania});
  }

  @font-face {
    font-family: BookmaniaLightItalic;
    src: url(${fontFiles.BookmaniaItalic});
  }

  @font-face {
    font-family: StyreneItalic;
    src: url(${fontFiles.StyreneItalic});
  }

  @font-face {
    font-family: StyreneA;
    src: url(${fontFiles.StyreneRegular});
  }

  @font-face {
    font-family: BookmaniaRegularItalic;
    src: url(${fontFiles.BookmaniaRegularItalic});
  }

  @font-face {
    font-family: StyreneMedium;
    src: url(${fontFiles.StyreneMedium});
  }

  * {
    outline: 0;
    box-sizing: border-box;
  }

  *:focus {
    outline: 0;
  }

  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
  }

  button {
    cursor: pointer;
  }

  body {
    margin: 0px !important;
    /* width: 375px !important; */
    overflow-x: hidden !important;
    -webkit-font-smoothing: antialiased;
  }
`
