import React, { useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import ArrowRightIcon from '../assets/icons/Arrow_Right.svg'
import { useAgentData } from '../hooks/useAgentData'
import Loader from '../components/Loader'

const homePageStyle = {
  container: {
    '--header-height': '150px',
    '--footer-height': '51.5px',
    '--header-footer-height': 'calc(var(--header-height, 0) + var(--footer-height, 0))',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    width: '100%',
    minHeight: 'max(22em, calc(100vh - calc(var(--header-footer-height, 0) + 40px )))',
    position: 'relative',
    zIndex: 1
  },
  centeredCell: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 'max(1.6em, 5vh)',
  },
  agentNameText: {
    fontFamily: 'BookmaniaLightItalic',
    color: 'var(--black)',
    fontSize: '35px',
    fontStyle: 'italic',
    fontWeight: '500',
    lineHeight: '35px',
    letterSpacing: '0px',
    textAlign: 'center',
  },
  greetingText: {
    fontFamily: 'BookmaniaBold',
    fontSize: '35px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '35px',
    letterSpacing: '0px',
    textAlign: 'center',
  },
  subtitle: {
    marginTop: '10px',
    fontFamily: 'BookmaniaLightItalic',
    fontSize: '23px',
    fontWeight: '400',
    lineHeight: '25px',
    letterSpacing: '0px',
    textAlign: 'center',
  },
  links: {
    width: '300px',
    borderRadius: '6px',
    backgroundColor: 'var(--blue)',
    marginTop: '20px',
    padding: '2px 0',
    fontFamily: 'StyreneA',
    fontSize: '14px',
    lineHeight: '14px',
    letterSpacing: '0.25px',
    fontWeight: 500,
    '& > li > a': {
      borderBottom: '1px solid rgba(255, 255, 255, 0.25)',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      minWidth: '16em',
      marginLeft: '20px',
      padding: '1px 0',
      cursor: 'pointer',
      textDecoration: 'none',
      color: '#FFF',
      '& > span': {
        flexGrow: 1,
        padding: '23px 0 22px 0'
      },
      '& > button': {
        marginRight: 8,
      }
    },
    '& > li:last-child > a': {
      borderBottom: '0px'
    }
  }
}

const links = {
  marketing: {
    title: 'Marketing Request',
    href: '/account/marketing'
  },
  signage: {
    title: 'Outdoor Signage',
    href: '/account/signage'
  },
  cmaRequestFlow: {
    title: 'Get Comps',
    href: '/account/cma/request',
    needTokenInUrl: true,
  },
  content: {
    title: 'Content Library',
    href: '/account/content'
  },
}

const getGreeting = () => {
  const currentHours = new Date().getHours();
  let greeting = 'Hello';
  if (currentHours < 12) {
    greeting = 'Good Morning';
  } else if (currentHours >= 12 && currentHours < 18) {
    greeting = 'Good Afternoon';
  } else {
    greeting = 'Good Evening';
  }
  return greeting;
};

const useStyles = makeStyles(homePageStyle)

const Home = () => {
  const styles = useStyles()
  const [isLoading, setIsLoading] = useState(true)
  const { agentId, token, agentName } = useAgentData()
  const marketingFlowAuthQueryParams = new URLSearchParams({ agentId, token })

  useEffect(() => {
    if (agentName) {
      setIsLoading(false)
    }
  }, [agentName])

  return (
    <div className={styles.container}>
      {isLoading ? (
        <Loader />
      ) : (
          <>
      <div className={styles.centeredCell}>
        <div className={styles.screenTitle}>
          <Typography className={styles.greetingText}>{getGreeting()},</Typography>
          <Typography className={styles.agentNameText}>{agentName || 'Agent'}.</Typography>
        </div>
        <Typography className={styles.subtitle}>What can we help you with?</Typography>
        <ul className={styles.links}>
          { Object.entries(links).map(([key, link]) => (
            <li key={key}>
              <a href={`${link.href}${link.needTokenInUrl ? '?' + marketingFlowAuthQueryParams : ''}`}>
                <span>{link.title}</span>
                <IconButton aria-label="go-forward">
                  <img src={ArrowRightIcon} alt="Go forward button" srcSet="" />
                </IconButton>
              </a>
            </li>
          )) }
        </ul>
      </div>
          </>
        )}
    </div>
  );
}

export default Home;
