import React, { useEffect, useState } from 'react'
import { makeStyles, StylesProvider } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { A8Button } from 'av8-ui'

const pageStyle = {
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 91px)',
    margin: 'auto',
    maxWidth: 650,
    paddingTop: '5%'
  },
  containerText: {
    textAlign: 'center'
  },
  button: {
    marginTop: 20
  },
  muted: {
    fontFamily: 'StyreneRegular',
    color: '#292929',
    fontSize: '12px',
    opacity: '0.65',
  },
  strong: {
    fontFamily: 'BookmaniaBold',
    color: '#292929',
    fontSize: '20px',
  },
}
const useStyles = makeStyles(pageStyle)

const Unauthorized = () => {
  const styles = useStyles()
  const [error, setError] = useState('')

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    setError(queryParams.get('error'))
  }, [])

  const renderChildrenBasedOnErrorType = () => {
    switch (error) {
      case 'not-registered': {
        return (
          <div className={styles.container}>
            <div className={styles.containerText}>
              <Typography className={styles.strong}>
                This e-mail is not registered in our platform.
              </Typography>
              <Typography className={styles.muted}>
                Please contact us if you're interested in joining Avenue 8.
              </Typography>
            </div>
            <StylesProvider injectFirst>
              <A8Button
                className={styles.button}
                variant="contained"
                onClick={() => window.location.href = `${window.location.origin}/join`}
              >Get in touch with us</A8Button>
            </StylesProvider>
          </div>
        )
      }
      default: {
        return (
          <div className={styles.container}>
            <div className={styles.containerText}>
              <Typography className={styles.strong}>
                Hmm, seems like you don't have authorization to access this!
              </Typography>
              <Typography className={styles.muted}>
                If you think this is a mistake: try signing in again or contact us!
              </Typography>
            </div>
          </div>
        )
      }
    }
  }

  return (
    <>
      { renderChildrenBasedOnErrorType()}
    </>
  )
}

export default Unauthorized
