import React, { useContext } from 'react'
import { BrowserRouter, Switch } from 'react-router-dom';
import { RouteWrapper as Route, PublicRouteWrapper as PublicRoute } from './Routes'
import Loader from "../components/Loader";
import Home from '../pages/Home'
import Unauthorized from '../pages/Unauthorized'
import LoginRedirect from '../pages/LoginRedirect'
import { AuthContext } from '../contexts/Auth.context'

export default function Routes() {
  const [{ authLoading }] = useContext(AuthContext)
  if (authLoading) {
    return (<Loader></Loader>)
  }
  return (
    <BrowserRouter>
      <Switch>
        <PublicRoute path="/account/unauthorized" exact component={Unauthorized} />
        <PublicRoute path="/account/login-redirect" component={LoginRedirect}/>
        <Route isPrivate path="/account" exact component={Home} />
      </Switch>
    </BrowserRouter>
  )
}
