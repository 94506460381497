import React, { useContext, useEffect } from 'react'
import { Route } from 'react-router-dom'
import Layout from '../pages/Layout'
import { SimpleFooter } from 'av8-ui'
import { AuthContext } from '../contexts/Auth.context'

export const RouteWrapper = ({
  component: Component,
  isPrivate,
  ...rest
}) => {
  const [state] = useContext(AuthContext)
  const { token } = state;

  useEffect(() => {
    if (!token && isPrivate) {
      window.location.href = `${window.location.origin}/login`
    }
  }, [token, isPrivate])

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  )
}

export const PublicRouteWrapper = ({
  component: Component,
  ...rest
}) => {
  return (
    <Route {...rest} render={(props) => (
      <>
        <Component {...props} />
        <SimpleFooter
          isLogoVisible
          logoHref={window.location.origin}
        />
      </>
    )} />
  )
}
